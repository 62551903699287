import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link, NavLink, useLocation } from 'react-router-dom';
import SongLyricsPage from './SongLyricsPage'; // Import the new component

const legends_gig_poster = require('./gig_images/legends_bar_poster.png');
const seven_stars_gig_poster = require('./gig_images/seven_stars_poster.png');
const sumac_centre_poster = require('./gig_images/sumac_centre_poster.png');
const billy_bootleggers_poster = require('./gig_images/billy_bootleggers_poster.png');

function HomePage() {
  const [showNewMusic, setShowNewMusic] = useState(true); //true shows new music false doesn't.


  return (
    <div className="page home-page">
      <h1>Welcome to the Lefthouse Website</h1>
      <p>Have a look around to find information about gig dates, music releases, and more!</p>
      <h2>Bio</h2>
      <p id="aboutusDesc">
        Lefthouse are a Nottinghamshire (UK) based rock band that began in mid-2022, creating and performing music somewhere on the spectrum between Post-Punk and Noise Rock.
      </p>
      <p id="aboutusDesc2">
        The band was founded and has always consisted of members Aaron Ashworth (Vocals, Guitar), Freddie Jones (Guitar), Zach Bladen (Bass) and Ben Wilson (Drums, Miscellaneous skill haver). Their sound is characterized by punchy, droning bass, loud and rhythmic guitars; and are heavily incrusted by symbolic, mellow vocals.
      </p>
      {showNewMusic && (
        <a id="latest_release_link" href="https://open.spotify.com/artist/78wnVBiI1NhjkBjihpJ30s" target="_blank" rel="noopener noreferrer">
          <h2>Latest Release:</h2>
          <p>Click here to listen to our new 2-track single 'Crescent Moon'.</p>
          <img id="cm_cover_img" src={require('./other_images/Cover_Crescent_Moon.png')} alt="Lefthouse Crescent Moon Cover Image" />
          <h2 class="blue_text">^ Click to listen ^</h2>
        </a>
      )}
      
    </div>
  );
}

function GigsPage() {
  const [gigs, setGigs] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  useEffect(() => {
    // For simplicity, I'll use the provided gigs array directly
    setGigs(gigsData);
  }, []);

  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value));
  };

  const filteredGigs = gigs.filter(gig => gig.date.includes(selectedYear));

  return (
    <div className="gigs-page" id="gigs">
      <h1 id="gigs_page_title">Lefthouse Gigs</h1>
      <section className="gig-section">
        <h1>Gigography</h1>
        <form onSubmit={(e) => e.preventDefault()}>
          <label className="gig-year-select" htmlFor="year">Select Year:</label>
          <select class="select_button" id="year" name="year" value={selectedYear} onChange={handleYearChange}>
            {/* Generate options from the current year back to 2023 */}
            {Array.from({ length: new Date().getFullYear() - 2022 }, (_, i) => new Date().getFullYear() - i).map((year, index) => (
              <option key={index} value={year}>{year}</option>
            ))}
          </select>
        </form>
      </section>
      {/* Iterate over filtered gigs and render each gig in a separate section */}
      {filteredGigs.map((gig, index) => (
        <section key={index} className="gig-section">
          <GigCard gig={gig} />
        </section>
      ))}
    </div>
  );
}

function GigCard({ gig }) {
  return (
    <section className="gig-card">
      <h2>{gig.name}</h2>
      <p>{gig.date}</p>
      <p>{gig.start_time}</p>
      <p>Venue: {gig.venue}</p>
      <p>Address: {gig.address}</p>
      {/* Wrap the image and the button in a div */}
      <div className="gig-details">
        {/* Use the imported image */}
        <a href={gig.media_link} target="_blank" rel="noopener noreferrer">
        <img id="gig_poster" src={gig.img_src} alt={gig.name} />
        </a>
      </div>
    </section>
  );
}
const gigsData = [
  {
    name: 'Live at Billy Bootleggers',
    date: '10th of September 2024',
    venue: 'Billy Bootleggers',
    address: 'Nottingham, NG1 2GB',
    start_time: '8:30PM',
    img_src: billy_bootleggers_poster, // Remove curly braces from here
    media_link: 'https://youtu.be/Ps0NB-gLUw8?si=YsvzPaMn3OmPTLyl',
    featured: false
  },
  {
    name: 'Live at The Sumac Centre',
    date: '3rd of May 2024',
    venue: 'Sumac Centre',
    address: '245 Gladstone Street, Nottingham, NG7 6HX',
    start_time: '8:45PM',
    img_src: sumac_centre_poster, // Remove curly braces from here
    media_link: 'https://www.instagram.com/p/C6dayMvtIci/',
    featured: false
  },
  {
    name: 'Live at The Seven Stars',
    date: '17th of February 2024',
    venue: 'The Seven Stars Freehouse Pub',
    address: '26 Church St, Riddings, DE55 4BX',
    start_time: '8:00PM',
    img_src: seven_stars_gig_poster, // Remove curly braces from here
    media_link: 'https://www.youtube.com/watch?v=4SuGL7vzDGg',
    featured: false
  },
  {
    name: 'Legends Sports Bar Hard Rock Night',
    date: '1st of December 2023',
    venue: 'Legends Sports Bar',
    address: 'Handley Arcade, 2a Leeming St, Mansfield NG18 1NQ',
    start_time: '7:30PM',
    img_src: legends_gig_poster, // Remove curly braces from here
    media_link: 'https://www.youtube.com/watch?v=5k_FAgh0MYw',
    featured: false
  },
];


function SocialMediaPage() {
  return (
    <div className="page socialmedia-page">
      <h1>Social Media</h1>
      <p className="inline100">Be sure to follow us on Social Media to stay updated in terms of what we're up to!</p>
      <div className="videoLinks">
      <h4>Follow Us On Spotify</h4>
      <div className="videoLinks">
        <a href="https://open.spotify.com/artist/78wnVBiI1NhjkBjihpJ30s" target="_blank" rel="noopener noreferrer">
          <img className="social-image" id="spotify-image" src={require('./logos/spotify-logo.png')} alt="Facebook image" />
        </a>
      </div>
        <h4>Follow Us On Instagram</h4>
        <a href="https://www.instagram.com/lefthouseband/" target="_blank" rel="noopener noreferrer">
          <img className="social-image" id="instagram_image" src={require('./logos/insta-logo.png')} alt="Lefthouse Instagram" />
        </a>
      </div>
      <h4>Follow Us On YouTube</h4>
      <div className="videoLinks">
        <a href="https://www.youtube.com/@lefthouse" target="_blank" rel="noopener noreferrer">
          <img className="social-image" src={require('./logos/youtube_channel_thumbnail.png')} alt="Lefthouse YouTube channel thumbnail image" />
        </a>
      </div>
      <h4>Follow Us On Facebook</h4>
      <div className="videoLinks">
        <a href="https://www.facebook.com/profile.php?id=100088426830361" target="_blank" rel="noopener noreferrer">
          <img className="social-image" id="facebook_image" src={require('./logos/facebook-logo.png')} alt="Facebook image" />
        </a>
      </div>
    </div>
  );
}

function ScrollToTopOnMount() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  }, [pathname]);

  return null;
}

function App() {
  const [darkMode, setDarkMode] = useState(true);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <Router>
      <div className={`App ${darkMode ? 'dark-mode' : ''}`}>
      <div className="above_navigation">
      <img id="lefthouse_banner_logo" src={require('./lefthouse_banner_logo.png')} alt="Lefthouse Logo" />
      
      <div className="media_links">
        <a href="https://www.instagram.com/lefthouseband/" target="_blank" rel="noopener noreferrer">
          <img className="social-image" id="instagram_nav_image" src={require('./logos/insta-logo.png')} alt="Lefthouse Instagram" />
        </a>
        <a href="https://open.spotify.com/artist/78wnVBiI1NhjkBjihpJ30s" target="_blank" rel="noopener noreferrer">
          <img className="social-image" id="spotify_nav_image" src={require('./logos/spotify-logo.png')} alt="Facebook image" />
        </a>
        <a href="https://www.youtube.com/@lefthouse" target="_blank" rel="noopener noreferrer">
          <img className="social-image" id="youtube_nav_image" src={require('./logos/youtube_channel_thumbnail.png')} alt="Lefthouse YouTube channel thumbnail image" />
        </a>
        <a href="https://www.facebook.com/profile.php?id=100088426830361" target="_blank" rel="noopener noreferrer">
          <img className="social-image" id="facebook_nav_image" src={require('./logos/facebook-logo.png')} alt="Facebook image" />
        </a>
      </div>
        
      </div>
      
          <nav className="navigation">
            <NavLink exact to="/" activeClassName="active">/home/</NavLink>
            <NavLink to="/gigs" activeClassName="active">/gigography/</NavLink>
            <NavLink to="/socialmedia" activeClassName="active">/social media/</NavLink>
            <NavLink to="/lyrics" activeClassName="active">/song lyrics/</NavLink>
          </nav>
        {/* <header className="App-header">
         
        </header> */}
        <div className="container">
          <ScrollToTopOnMount />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/gigs" element={<GigsPage />} />
            <Route path="/socialmedia" element={<SocialMediaPage />} />
            <Route path="/lyrics" element={<SongLyricsPage />} /> {/* Add the new route */}
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
